/* Style for the body of the page */
body {
  font-family: 'Calibre', sans-serif;
  background-color: #0a192f;
  color: #ccd6f6;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

/* Style for headers */
h1, h2, h3, h4 {
  
  color: #ccd6f6;
}

h1 {
  text-align: center;
  margin-bottom:   20px;
  /* color: #64ffda; */
  /* margin-top: 2rem; */
  
}

/* Style for links */
a {
  color: #64ffda;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #52c7c7;
}

/* Style for the wrapper */
/* .wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
} */

/* Style for the content */
.content {
  padding: 2rem;
  background-color: #112240;
  margin: 2rem auto;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
}


/* Style for the footer */
.footer {
  background-color: #0a192f;
  color: white;
  text-align: center;
  padding: 0.5rem 0; 
  font-size: 0.8rem;
  position: relative;
}

.footer p {
  margin-bottom: 10px;
  font-size: 0.8rem; 
}

/* Style for buttons */
button {
  background-color: #64ffda;
  color: #0a192f;
  border: 2px solid #64ffda;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

button:hover {
  background-color: #52c7c7;
  color: #ffffff;
  border-color: #52c7c7;
}

/* Style for project cards */
.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.project-card {
  padding: 1rem;
  background-color: #112240;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.project-card p {
  font-size: 1rem;
  color: rgb(187, 187, 189);
}

.project-card a {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  color: #ffffff;
  background-color: #0c4150;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.project-card a:hover {
  background-color: #3a8cbe;
  transform: translateY(-1px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.tech-tag {
  display: inline-block;
  background-color: #333;
  color: white;
  padding: 0.3em 0.6em;
  margin: 0.2em;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: bold;
}


/* Style for the experience section */
.experience-list {
  list-style-type: none;
  padding: 2rem 0;
}

.experience-item {
  background-color: #112240;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.experience-item h2 {
  margin: 0.5rem 0;
  font-size: 1.5rem;
}

.experience-item h3 {
  margin: 0.3rem 0;
  font-size: 1.2rem;
  color: #afafafdc;
}

.experience-item p {
  margin: 0.3rem 0;
  color: #ccd6f6;
}

.experience-item ul {
  list-style-type: disc;
  margin: 0.5rem 0 0 1rem;
  padding: 0;
}

/* Style for the contact form */
form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 1rem;
  margin-top: 1rem;
}

label {
  font-weight: bold;
  align-self: flex-start;
}

input, textarea {
  width: 95%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea{
  height: 150px;
}

button {
  display: inline-block;
  width: auto;
  background-color: #64ffda;
  color: #0a192f;
  border: 2px solid #64ffda;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  align-self: center;
}

button:hover {
  background-color: #52c7c7;
  color: #ffffff;
  border-color: #52c7c7;
}

/* Responsive design for small screens */
@media (max-width: 768px) {
 
  .social-links {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .content, .project-card {
    width: auto;
    margin: 0 auto;
  }

  .project-grid {
    grid-template-columns: 1fr; /* Single column for small screens */
  }

  .social-link span, .social-icon span {
    font-size: 0.9rem; /* Adjust font size for better readability */
  }

  h1 {
    font-size: 1.8rem;
  }

  h2, h3 {
    font-size: 1.5rem;
  }

}

