.about-container {
  text-align: center;
  padding-top: 4rem;
}

.profile-pic {
  width: 170px;
  height: 170px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin: 0 auto 1rem;
  border-radius: 50%;
  border: 2px solid #64ffda;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  color: #ccd6f6;
}

@media (max-width: 768px) {

  .about-container {
    padding-top: 2.5rem;
  }

  p{
    width: 100%;
  }

}