nav {
  background-color: #0a192f;
  color: #64ffda;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 5rem;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  color: #64ffda;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #52c7c7;
  text-decoration: underline;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 20px;
  height: 3px;
  background-color: #64ffda;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
    position: absolute;
    left: 10px;
    z-index: 1000;
    top: 10px;
  }

  nav ul {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: #0a192f;
    width: 90%;
    top: 0;
    left: 0;
    padding: 0.5rem 0;
  }

  nav ul.open {
    display: flex;
  }

  
}