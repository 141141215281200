@media (max-width: 768px) {

  form{
    display: flex;
    flex-direction: column;
    align-items: center;
  };
    
.content {
    max-width: 100%;
    word-wrap: break-word;
  }
  
  a {
    word-wrap: break-word;
    white-space: normal;
  }

  input, textarea {
    width: 80%;
  }

  button {
    width: 100%; 
    text-align: center;
  }
}
