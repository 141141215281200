
.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 2rem;
    justify-items: center;
    align-items: center;
    padding-top: 2rem;
}
  

.skill {
    text-align: center;
    color: #64ffda;
    transition: transform 0.3s;
}
  
.skill:hover {
    transform: scale(1.1);
}

.skill p {
    margin-top: 10px;
    font-size: 1rem;
    color: #ccd6f6;
}

@media (max-width: 768px) {
    .skills-grid {
      grid-template-columns: repeat(2, 1fr); /* Two items per row on small screens */
    }
  }