.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.scroll-to-top {  justify-content: center;
  display: flex;
  width: 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #64ffda;
  color: #0a192f;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.5s;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: #52c7c7;
  color: #ffffff;
}

.scroll-to-top.hidden {
  opacity: 0;
  pointer-events: none;
}

.scroll-to-top i {
  font-size: 2rem; /* Size of the arrow */
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 2rem;
  background-color: #0a192f;
  margin: 2rem auto;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  .scroll-to-top {
    bottom: 5px;
    right: 5px;
    padding: 5px 10px;
    font-size: 0.7rem;
  }
}